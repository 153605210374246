export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
    title: "Choose a Course",
    desc: "To provide accessible, flexible, and high-quality education that empowers learners to grow and succeed anywhere, anytime.",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: "Learn with Expert",
    desc: "Allowing learners to learn directly from experts and master these skills, while gaining a deeper understanding of cultural traditions and enhancing personal development through authentic, hands-on learning experiences.",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/diploma.png",
    title: "Earn a Certificate",
    desc: "To preserve and promote Tamil language, traditional music, yoga, and culture through accessible online courses, empowering learners to master these timeless arts.",
  }
]
export const awrapper = [
  {
    cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
    data: "3,000",
    title: "SUCCESS STORIES",
  },

  {
    cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
    data: "320",
    title: "TRUSTED TUTORS",
  },
  {
    cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
    data: "1,000",
    title: "SCHEDULES",
  },
  {
    cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
    data: "587",
    title: "COURSES",
  },
]
export const coursesCard = [
  {
    id: 1,
    cover: "../images/courses/c1.png",
    coursesName: "Degree Programs",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by John Smith",
        totalTime: "50 lectures (190 hrs)",
      },
    ],
    priceAll: "$100 All Course",
    pricePer: "$15 per month",
  },
  {
    id: 2,
    cover: "../images/courses/c2.png",
    coursesName: "Diploma Programs",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Ram Gurung",
        totalTime: "30 lectures (125 hrs)",
      },
    ],
    priceAll: "$200 All Course",
    pricePer: "$25 per month",
  },
  {
    id: 3,
    cover: "../images/courses/c3.png",
    coursesName: "Certificate Programs",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Saroj Nepal",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    priceAll: "$50 All Course",
    pricePer: "$5 per month",
  },
  {
    id: 4,
    cover: "../images/courses/c4.png",
    coursesName: "Introducing to Programming with WordPress",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Prabin Thapa Mager",
        totalTime: "20 lectures (20 hrs)",
      },
    ],
    priceAll: "$30 All Course",
    pricePer: "$3 per month",
  },
  {
    id: 5,
    cover: "../images/courses/c5.png",
    coursesName: "Introducing to Programming with ReactJS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "100 lectures (150 hrs)",
      },
    ],
    priceAll: "$300 All Course",
    pricePer: "$30 per month",
  },
  {
    id: 6,
    cover: "../images/courses/c6.png",
    coursesName: "Learn Frontend Programming Language",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "200 lectures (300 hrs)",
      },
    ],
    priceAll: "$500 All Course",
    pricePer: "$80 per month",
  },
  {
    id: 7,
    cover: "../images/courses/c7.png",
    coursesName: "Introducing to with HTML / CSS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Price",
        totalTime: "20 lectures (50 hrs)",
      },
    ],
    priceAll: "$20 All Course",
    pricePer: "$2 per month",
  },
  {
    id: 8,
    cover: "../images/courses/c8.png",
    coursesName: "Introducing to with JAVA",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Petter",
        totalTime: "80 lectures (200 hrs)",
      },
    ],
    priceAll: "$200 All Course",
    pricePer: "$40 per month",
  },
  {
    id: 9,
    cover: "../images/courses/c9.png",
    coursesName: "Introducing to with HTML / CSS / JAVASCRIPT",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Petter",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    priceAll: "$20 All Course",
    pricePer: "$3 per month",
  },
]
export const online = [
  // {
  //   cover: "./images/courses/online/o1.png",
  //   hoverCover: "./images/courses/online/o1.1.png",
  //   courseName: "UI/UX Design Courses",
  //   course: "25 Courses",
  // },
  {
    cover: "./images/courses/online/o2.png",
    hoverCover: "./images/courses/online/o2.1.png",
    courseName: "Language",
    course: "25 Courses",
  },
  // {
  //   cover: "./images/courses/online/o3.png",
  //   hoverCover: "./images/courses/online/o3.1.png",
  //   courseName: "Computer Science",
  //   course: "10 Courses",
  // },
  {
    cover: "./images/courses/online/o4.png",
    hoverCover: "./images/courses/online/o4.1.png",
    courseName: "Literature",
    course: "15 Courses",
  },
  {
    cover: "./images/courses/online/o5.png",
    hoverCover: "./images/courses/online/o5.1.png",
    courseName: "Technology",
    course: "30 Courses",
  },
  // {
  //   cover: "./images/courses/online/o6.png",
  //   hoverCover: "./images/courses/online/o6.1.png",
  //   courseName: "Information Software",
  //   course: "60 Courses",
  // },
  {
    cover: "./images/courses/online/o10.png",
    hoverCover: "./images/courses/online/o10.1.png",
    courseName: "Music",
    course: "120 Courses",
  },
  {
    cover: "./images/courses/online/o7.png",
    hoverCover: "./images/courses/online/o7.1.png",
    courseName: "Yoga",
    course: "10 Courses",
  },
  // {
  //   cover: "./images/courses/online/o8.png",
  //   hoverCover: "./images/courses/online/o8.1.png",
  //   courseName: "Marketing",
  //   course: "30 Courses",
  // },
  {
    cover: "./images/courses/online/o10.png",
    hoverCover: "./images/courses/online/o10.1.png",
    courseName: "Dance",
    course: "120 Courses",
  },
  // {
  //   cover: "./images/courses/online/o11.png",
  //   hoverCover: "./images/courses/online/o11.1.png",
  //   courseName: "Business Administration",
  //   course: "17 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o12.png",
  //   hoverCover: "./images/courses/online/o12.1.png",
  //   courseName: "Web Management",
  //   course: "17 Courses",
  // },
]
export const team = [
  {
    cover: "./images/testo/pr.png",
    name: "Ph.D SELVANAYAKI SRIDAS",
    work: "LEAD INSTRUCTOR",
  },
  {
    cover: "./images/testo/sec.png",
    name: "SUGANTHAN VALLIPURAM",
    work: "INSTRUCTOR",
  },
  {
    cover: "./images/testo/tes.png",
    name: "KUMUDINI PONNUTHURAI",
    work: "INSTRUCTOR",
  },
  {
    cover: "./images/testo/vp.png",
    name: "ANANTHY SASITHARAN",
    work: "INSTRUCTOR",
  }
]
export const price = [
  {
    name: "BASIC PLAN",
    price: "49K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "BEGINNER PLAN",
    price: "79K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "PREMIUM PLAN",
    price: "109k",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "ULTIMATE PLAN",
    price: "149K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
]
export const faq = [
  {
    title: "How to Enroll This Online Courses?",
    desc: "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Where It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How to Enroll This Online Courses?",
    desc: "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Where It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
]
export const blog = [
  {
    id: 1,
    type: "admin",
    date: "JAN. 18, 2021",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b1.webp",
  },
  {
    id: 2,
    type: "admin",
    date: "API. 25, 2022",
    com: "5 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b2.webp",
  },
  {
    id: 3,
    type: "user",
    date: "MAY. 15, 2022",
    com: "10 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b3.webp",
  },
  {
    id: 4,
    type: "admin",
    date: "JAN. 02, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b4.webp",
  },
  {
    id: 5,
    type: "admin",
    date: "DEC. 14, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b5.webp",
  },
  {
    id: 6,
    type: "user",
    date: "JAN. 18, 2021",
    com: "12 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b6.webp",
  },
]
export const testimonal = [
  {
    id: 1,
    name: "DR.SELVANAYAKI SRIDAS",
    post: "President / Administrator",
    desc: "To cultivate a vibrant, inclusive community where every individual thrives, learns, and grows to become a confident, compassionate leader.",
    cover: "./images/testo/pr.png",
  },
  {
    id: 2,
    name: "DR.ILA SUNDARAM",
    post: "Director / Principal",
    desc: "Ensuring academic excellence, smooth administration, student and staff development, and a positive learning environment. They inspire, guide, and manage resources to achieve the institution’s vision and success",
    cover: "./images/testo/prr.png",
  },
  {
    id: 3,
    name: "SUGANTHAN VALLIPURAM",
    post: "Secretary",
    desc: "To ensure effective communication, seamless organization, and a well-coordinated environment that empowers our community to achieve its goals with clarity and efficiency.",
    cover: "./images/testo/sec.png",
  },
  {
    id: 4,
    name: "KUMUDINI PONNUTHURAI",
    post: "Treasurer",
    desc: "To manage resources responsibly, ensure financial transparency, and support the growth and sustainability of our institution through careful planning and strategic budgeting.",
    cover: "./images/testo/tes.png",
  },
  {
    id: 5,
    name: "ANANTHY SASITHARAN",
    post: "Vice-President",
    desc: "To support and strengthen our community by fostering collaboration, encouraging innovation, and ensuring every voice is heard and valued.",
    cover: "./images/testo/vp.png",
  }
]
